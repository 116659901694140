import React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';

const CssTextField = styled(TextField)({
  '& .MuiInput-root': {
    backgroundColor: 'var(--light-blue)',
    borderRadius: '4px',
    padding: '10px 15px 10px 15px',
  },
  '& input': {
    padding: '0px',
  }
});

export const Searchbar = ({ placeholder = "Søk", onChange, fullWidth = false }) => {
  return (
    <CssTextField
      id="custom-css-outlined-input"
      placeholder={placeholder}
      variant="standard"
      fullWidth={fullWidth}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" sx={{ color: 'black' }}>
            <Search />
          </InputAdornment>
        ),
        disableUnderline: true,
      }}
    />
  );
}
